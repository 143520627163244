<template>
  <v-app class="admin-app">
    <v-navigation-drawer v-model="drawer" app color="accent" dark>
      <router-link :to="{ name: 'home' }">
        <v-img src="@/assets/logo-w.svg" class="ma-6" />
      </router-link>
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="white">
            <v-icon color="primary">person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          @click.prevent="goTo(item)"
          :to="item.link"
          :exact="item.exact"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item link @click.prevent="signOut()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Çıkış</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ title }}</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="content">
      <router-view @changeTitle="changeTitle" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    title: "",
    currentMenuItem: {},
    menuItems: [
      {
        icon: "dashboard",
        title: "Burs Başvuruları",
        link: {
          name: "admin-home"
        },
        exact: true
      },
      {
        icon: "mdi-image-area",
        title: "Bannerlar",
        link: {
          name: "admin-banners"
        },
        exact: true
      },
      {
        icon: "mdi-image-area",
        title: "Bannerlar (En)",
        link: {
          name: "admin-banners-en"
        },
        exact: true
      },
      {
        icon: "mdi-newspaper-variant-multiple",
        title: "Haberler",
        link: {
          name: "admin-news"
        },
        exact: true
      },
      {
        icon: "mdi-newspaper-variant-multiple",
        title: "Haberler (En)",
        link: {
          name: "admin-news-en"
        },
        exact: true
      },
      {
        icon: "mdi-television-ambient-light",
        title: "Basında KAHEV",
        link: {
          name: "admin-press"
        },
        exact: true
      }
    ]
  }),
  computed: {
    userSignedIn() {
      return this.$store.getters["auth/userSignedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
      this.$router.push({ name: "home" }).catch(() => {
        return false;
      });
    },
    goTo(item) {
      this.currentMenuItem = item;
      this.title = item.title;
      this.$router.push(item.link).catch(() => {
        return false;
      });
    },
    changeTitle(title) {
      this.title = title;
    }
  },
  mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;
  }
};
</script>

<style lang="scss" scoped>
.admin-app {
  .content {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }

  .course {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    .v-card__title {
      min-height: 50px;
      align-items: flex-start;
    }

    .v-card__subtitle {
      span {
        display: inline-block;
        width: 25px;
        height: 5px;
        border-radius: 2px;
        margin-left: 10px;
      }
    }
  }
}
</style>
